exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-clients-hashifoundation-js": () => import("./../../../src/pages/clients/hashifoundation.js" /* webpackChunkName: "component---src-pages-clients-hashifoundation-js" */),
  "component---src-pages-clients-index-js": () => import("./../../../src/pages/clients/index.js" /* webpackChunkName: "component---src-pages-clients-index-js" */),
  "component---src-pages-hire-us-js": () => import("./../../../src/pages/hire-us.js" /* webpackChunkName: "component---src-pages-hire-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opensource-code-of-conduct-js": () => import("./../../../src/pages/opensource/code-of-conduct.js" /* webpackChunkName: "component---src-pages-opensource-code-of-conduct-js" */),
  "component---src-pages-opensource-index-js": () => import("./../../../src/pages/opensource/index.js" /* webpackChunkName: "component---src-pages-opensource-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tshirt-js": () => import("./../../../src/pages/tshirt.js" /* webpackChunkName: "component---src-pages-tshirt-js" */)
}

